import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', padding: '50px 0' }}>
      <SEO title="404: Not found" />
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist</p>
      <p><Link to="/">Go back</Link></p>
    </div>
  </Layout>
)

export default NotFoundPage
